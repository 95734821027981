<!DOCTYPE html>
<!--[if lt IE 7]>      <html class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->
<!--[if IE 7]>         <html class="no-js lt-ie9 lt-ie8"> <![endif]-->
<!--[if IE 8]>         <html class="no-js lt-ie9"> <![endif]-->
<!--[if gt IE 8]><!--> 
<html class="no-js"> <!--<![endif]-->
    <head>
        <meta charset="utf-8">
        <title>Jandakot Lakes Junior Cricket Club</title>
        <meta name="description" content="">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <!--[if gte IE 9]>
        	<link rel="stylesheet" type="text/css" href="css/ie9.css" />
		<![endif]-->
				<link href='http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300,600,700%7CRaleway:700' rel='stylesheet' type='text/css'>
				
				<script type="application/ld+json">
				{
					"@context": "http://schema.org",
					"@type": "Organization",
					"url": "http://www.jandakotlakesjcc.com.au",
					"name": "Jandakot Lakes Junior Cricket Club",
					"description": "Supporting junior cricket in Atwell, Perth"
				}
				</script>
    </head>
    <body>
    	<div class="loader">
    		<div class="spinner">
			  <div class="double-bounce1"></div>
			  <div class="double-bounce2"></div>
			</div>
    	</div>
				
		<div class="nav-container">
		</div>
		
		<div class="main-container">
			<header class="page-header">
				<div class="background-image-holder parallax-background">
					<img class="background-image" alt="Background Image" src="assets/img/cricketback.jpg">
				</div>
				
				<div class="container">
					<div class="row">
						<div class="col-sm-12">
							<span class="text-white alt-font">Welcome to</span>
							<h1 class="text-white">Jandakot Lakes</h1>
							<h1 class="text-white">Junior Cricket Club</h1>
						</div>
					</div>
				</div>
			</header>

			<section class="strip bg-secondary-1">
				<div class="container">
					<div class="row clearfix">
						<div class="col-sm-6 col-xs-12 pull-left">
							<h3 class="text-white"><strong>Registrations for 2021/2022 are currently open</strong> </h3>
						</div>
						
						<div class="col-sm-4 col-xs-12 pull-right text-right">
							<a href="https://www.playcricket.com.au/club-finder/club-details?Id=6544" target="_blank" 	class="btn btn-lg btn-primary btn-white">Register now!</a>
						</div>
					</div>
				</div>
			</section> 

			<section class="no-pad clearfix">

				<div class="col-md-6 col-sm-12 no-pad">
					<div class="feature-box bg-muted">
						<h1>Join us on Facebook</h1>
						<p>
							You can now follow your club on Facebook at the <a href="https://www.facebook.com/jandakotlakesjcc/" target="_blank">Jandakot Lakes JCC</a> page. Like the page for updates on your feed.
						</p>
						<!---<h1>Registration day for 2019/20</h1>
						<p>
							Registration and uniform day is on Saturday 22nd of September from 9-11:30 am in the club rooms at Atwell Oval.
							<br/><br/>
							And don't forget, you can register directly on the PlayCricket website:
						</p>-->
						<!--<a href="assets/docs/girlsleague.pdf" target="_blank" class="btn btn-lg btn-primary">Tell Me More</a>-->
						<a href="https://www.facebook.com/jandakotlakesjcc/" target="_blank" 	class="btn btn-lg btn-primary">Join us</a>
					</div>
					<div class="feature-box bg-muted">
						<h1>Child Safety</h1>
						<p>
							The Jandakot Lakes cricket club endorse and adopt Australian Crickets policy for safeguarding children and young people, Australian crickets ’Looking after our kids’ code of behaviour for affiliated associations and clubs, and Australian crickets commitment to safeguarding children and young people effective as of 16th October 2021.
						</p>
						<p>
							Please see attached the following documents:
							<BR><a href="assets/docs/AC_Child_Safety_Policy_for_Safeguarding_Children_and_Young_People.pdf" target="_blank">Child Safety Policy for Safeguarding Children and Young People</a>
							<BR><a href="assets/docs/Looking_After_Our_Kids_for_Associations_Clubs_Updated.pdf" target="_blank">Looking After our Kids</a>
							<BR><a href="assets/docs/AC_Child_Safety_Commitment_Statement.pdf" target="_blank">Child Safety Commitment Statement</a>
						</p>
						<br/>
						<a href="https://www.instagram.com/jandakot_lakes_junior_cricket/" target="_blank" 	class="btn btn-lg btn-primary">Join us</a>
					</div>
					<div class="feature-box bg-muted">
						<h1>Join us on Instragram</h1>
						<p>
							You can now follow your club on Instragram at the <a href="https://www.instagram.com/jandakot_lakes_junior_cricket/" target="_blank">Jandakot Lakes JCC</a> page. Like the page for updates on your feed.
						</p>
						<br/>
						<a href="https://www.instagram.com/jandakot_lakes_junior_cricket/" target="_blank" 	class="btn btn-lg btn-primary">Join us</a>
					</div>

				</div>
				
				<div class="col-md-6 col-sm-12 no-pad">
					<div class="feature-box">
						<img class="background-image" alt="Background Image" src="assets/img/JLJCC_Pathway.jpg">
					</div>
				</div>
				<!--<div class="col-md-6 col-sm-12 no-pad">
					<div class="feature-box">
						<img class="background-image" alt="Background Image" src="assets/img/discover-girls.jpg">
					</div>
				</div> -->
			</section>
			
			
			<section class="team-1">
				<div class="container">
					<div class="row">
						<div class="col-md-8 col-md-offset-2 text-center">
							<h1>Our Club Committee</h1>
						</div>
					</div>
					
					<div class="row">
						<div class="col-md-6 col-sm-4">
							<div class="team-1-member">
								<img alt="Team Member" class="background-image" src="assets/img/brian.png">
								<h5>Brian Mathews</h5>
								<span>President</span><br>
								<span><a href='mailto:president.jandakotlakesjcc@gmail.com'>president.jandakotlakesjcc@gmail.com</a></span><br>
								<span>0417775971</span><br>
							</div>
						</div>
						
						<div class="col-md-6 col-sm-4">
							<div class="team-1-member">
								<img alt="Team Member" class="background-image" src="assets/img/dani.png">
								<h5>Danielle Gray</h5>
								<span>Vice President</span><br>
								<span><a href='mailto:vicepresident@jandakotlakesjcc.com.au'>vicepresident@jandakotlakesjcc.com.au</a></span>
							</div>
						</div>
						
					</div>

					<div class="row">

						<div class="col-md-4 col-sm-4">
							<div class="team-1-member">
								<img alt="Team Member" class="background-image" src="assets/img/suzie.png">
								<h5>Suzie Hallows</h5>
								<span>Treasurer</span><br>
								<span><a href='mailto:treasurer.jandakotlakesjcc@gmail.com'>treasurer.jandakotlakesjcc@gmail.com</a></span>
							</div>
						</div>

						<div class="col-md-4 col-sm-4">
							<div class="team-1-member">
								<img alt="Team Member" class="background-image" src="assets/img/geraldine.png">
								<h5>Geraldine Stewart</h5>
								<span>Registrar</span><br>
								<span></span><br>
								<span><a href='mailto:registrar.jandakotlakesjcc@gmail.com'>registrar.jandakotlakesjcc@gmail.com</a></span>
							</div>
						</div>

						<div class="col-md-4 col-sm-4">
							<div class="team-1-member">
								<img alt="Team Member" class="background-image" src="assets/img/melanie.png">
								<h5>Melanie Walker</h5>
								<span>Secretary</span><br>
								<span><a href='mailto:admin@jandakotlakesjcc.com.au'>admin@jandakotlakesjcc.com.au</a></span>
							</div>
						</div>
						
					</div>
					
				</div>
			</section>

			<section class="duplicatable-content">
				
					<div class="container">
						<app-minutes></app-minutes>
					</div>
				
				</section>
			
			<section class="clients">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 text-center">
							<p class="lead">Please support our club sponsors</p>
						</div>
					</div>
					
					<div class="row client-row">
						<div class="row-wrapper">
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/willeton-trophy-centre.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/cricket-and-football-shop.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/dominos_logo_bw.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/cargiantwa-trans-gs.png">
							</div>
							
						</div>
					</div>
<!--							<div class="col-sm-3 text-center">
					
					<div class="row client-row">
						<div class="row-wrapper">
						
								<img alt="client" src="assets/img/tyrepower.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/toyworld.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/tully-real-estate.png">
							</div>
						
							<div class="col-sm-3 text-center"></div>

						</div>
					</div>
-->
					<div class="row client-row">
						<div class="row-wrapper">
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/perth-scorchers.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/t20-blast.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/waca.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/western-fury.png">
							</div>
							
						</div>
					</div>
					
					<div class="row client-row">
						<div class="row-wrapper">
						
							<div class="col-sm-3 text-center"></div>

							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/western-warriors.png">
							</div>
						
							<div class="col-sm-3 text-center">
								<img alt="client" src="assets/img/cricket-aus.png">
							</div>
						
							<div class="col-sm-3 text-center"></div>

						</div>
					</div>

				</div>
			</section>

			<section class="no-pad clearfix">
				<div class="col-md-6 col-sm-12 no-pad">
					<div class="feature-box">
						<img class="background-image" alt="Good Sports" src="assets/img/Good Sports logo.JPG">
					</div>
				</div>
				<div class="col-md-6 col-sm-12 no-pad">
					<div class="feature-box">
						<img class="background-image" alt="Smoke Free" src="assets/img/Smoke-Free-social-sm.png">
					</div>
				</div>
				</section>


			<section class="map">
				<div class="map-holder">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3378.303741840182!2d115.86046341477513!3d-32.1421008389426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32976619201d2b%3A0xf04f0b618f26140!2sAtwell+Reserve!5e0!3m2!1sen!2sau!4v1498311988990" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
				</div>
			</section>
			
			<section class="contact-thirds">
				<div class="container">
					<div class="row">
							<div class="col-sm-12 text-center">
								<h1>Club Information</h1>
							</div>
					</div>
					
					<div class="row">
						<div class="col-sm-4">
							<h5>Our Club And Our Goals.</h5>
							<p>
								Here at Jandakot Lakes Junior Cricket Club we want to teach boys and girls how to play Australia's National sport in a safe and fun manner.
							</p>
							
							<p>
								Our club has made a strong point of ensuring that our players have coaches with a minimum level one coaching to ensure everything that your child learns is being taught the correct way. From Blasters right into Junior Cricket Jandakot Lakes believes that by teaching our players the correct way to Bat, Bowl or Field will help them to not only learn to play the game correctly but help with their confidence and enjoyment of the game.
							</p>
						</div>
						
						<div class="col-sm-4 text-center">
							<h5>Details</h5>
							<p class="lead">
								Brenchley Avenue, Atwell.<br/>
								WA 6164
							</p>
							<br/>
							<h5>Payment Details</h5>
							<p class="lead">
								BSB: 066202<br/>
								Account: 10110766
							</p>
						</div>
						
					</div>
				</div>
			</section>
		</div>
		
		<div class="footer-container">
		</div>
		
    </body>
</html>
				