<div class="row">
  <div class="col-md-12 text-center">
    <h1>Club Documentation</h1>
  </div>
</div>

<div class="row">
  <div class="col-sm-6">
    <div class="feature feature-icon-large">
      <div class="pull-left">
        <i class="fa fa-newspaper-o"></i>
      </div>
      <div class="pull-right">
        <h5>Meeting Minutes</h5>
        <p>
          Minutes from our recent committee meetings and AGM:
          <ul>
            <li><a href="assets/docs/minutes-agm-2021.pdf" target="_blank">Minutes for 2021 AGM</a></li>
            <li><a href="assets/docs/minutes-2020-12.pdf" target="_blank">Minutes for December 2020</a></li>
            <li><a href="assets/docs/minutes-2020-09.pdf" target="_blank">Minutes for September 2020</a></li>
            <li><a href="assets/docs/minutes-2020-08.pdf" target="_blank">Minutes for August 2020</a></li>
            <li><a href="assets/docs/minutes-2020-07.pdf" target="_blank">Minutes for July 2020</a></li>
            <li><a href="assets/docs/minutes-agm-2020.pdf" target="_blank">Minutes for 2020 AGM</a></li>
            <li><a href="assets/docs/minutes-2020-03.pdf" target="_blank">Minutes for March 2020</a></li>
            <li><a href="assets/docs/minutes-2020-02.pdf" target="_blank">Minutes for February 2020</a></li>
            <li><a href="assets/docs/Minutes-2019-12.pdf" target="_blank">Minutes for December 2019</a></li>
            <li><a href="assets/docs/Minutes-2019-11.pdf" target="_blank">Minutes for November 2019</a></li>
            <li><a href="assets/docs/Minutes-2019-10.pdf" target="_blank">Minutes for October 2019</a></li>
            <li><a href="assets/docs/Minutes-2019-09.pdf" target="_blank">Minutes for September 2019</a></li>
            <li><a href="assets/docs/minutes-2019-08.docx" target="_blank">Minutes for August 2019</a></li>
            <li><a href="assets/docs/minutes-2019-07.docx" target="_blank">Minutes for July 2019</a></li>
            <li><a href="assets/docs/minutes-2019-06.docx" target="_blank">Minutes for June 2019</a></li>
            <li><a href="assets/docs/minutes-agm-2019.docx" target="_blank">Minutes for 2019 AGM</a></li>
            <!--<li><a href="assets/docs/minutes-2019-04.docx" target="_blank">Minutes for April 2019</a></li>
            <li><a href="assets/docs/minutes-2019-03.docx" target="_blank">Minutes for March 2019</a></li>
            <li><a href="assets/docs/minutes-2019-02.docx" target="_blank">Minutes for February 2019</a></li>
            <li><a href="assets/docs/minutes-2019-01.docx" target="_blank">Minutes for January 2019</a></li>
            <li><a href="assets/docs/minutes-2018-12.docx" target="_blank">Minutes for December 2018</a></li>
            <li><a href="assets/docs/minutes-2018-11.docx" target="_blank">Minutes for November 2018</a></li>
            <li><a href="assets/docs/minutes-2018-10.docx" target="_blank">Minutes for October 2018</a></li>
            <li><a href="assets/docs/minutes-2018-09.docx" target="_blank">Minutes for September 2018</a></li>
            <li><a href="assets/docs/minutes-2018-08.docx" target="_blank">Minutes for August 2018</a></li>
            <li><a href="assets/docs/minutes-2018-07.docx" target="_blank">Minutes for July 2018</a></li>
            <li><a href="assets/docs/minutes-2018-06.docx" target="_blank">Minutes for June 2018</a></li>
            <li><a href="assets/docs/minutes-agm-2018.docx" target="_blank">Minutes for 2018 AGM</a></li>
            <li><a href="assets/docs/minutes-2017-11.docx" target="_blank">Minutes for November 2017</a></li>
            <li><a href="assets/docs/minutes-2017-10.docx" target="_blank">Minutes for October 2017</a></li>
            <li><a href="assets/docs/minutes-2017-09.docx" target="_blank">Minutes for September 2017</a></li>-->
          </ul>
      </div>
    </div>
  </div>
  
  <div class="col-sm-6">
    <div class="feature feature-icon-large">
      <div class="pull-left">
          <i class="fa fa-newspaper-o"></i>
        </div>
      <div class="pull-right">
        <h5>Rules and other documents</h5>
        <p>
          Below are the rules and other documents to help on game day
        </p>
        <p>
          <a href="http://southwestmjcc.wa.cricket.com.au/" target="_blank">SWMJCC Guides and Policies</a><BR>
          <a href="http://cjcc.wa.cricket.com.au/" target="_blank">Law summary for U12s-17s</a><BR>
          <a href="http://southwestmjcc.wa.cricket.com.au/" target="_blank">Junior umpire forms</a><BR>
              <!--<a href="assets/docs/App_D_SWMJCC_junior_umpire_payment_2019_20v.1.pdf" target="_blank">Junior umpire payment form</a><BR>-->
          <a href="assets/docs/No_go_zones.pdf" target="_blank">No-go zones of the field</a><BR>
          <a href="assets/docs/JLT_Game_Day_Checklist.pdf" target="_blank">JLT Game Day Checklist</a><BR>
          </p>
          <p>
          <a href="assets/docs/child_safety_committment.pdf" target="_blank">Safeguarding Kids in Cricket Commitment Statement</a><BR>
          <a href="assets/docs/Good_Sports_Junior_Club_Policy_Executed.pdf" target="_blank">Good Sports Junior Club policy</a><BR>
          <a href="assets/docs/SmokingManagementPolicyExecuted.pdf" target="_blank">Good Sports Smoking Management policy</a><BR>
          </p>
          <p>
          <a href="assets/docs/Accreditation_Certificate.pdf" target="_blank">Good Sports Accreditation Certificate - Level 1</a><BR>
          <a href="assets/docs/Accreditation_Certificate2.pdf" target="_blank">Good Sports Accreditation Certificate - Level 2</a><BR>
          <a href="assets/docs/Accreditation_Certificate3.pdf" target="_blank">Good Sports Accreditation Certificate - Level 3</a><BR>
          <a href="assets/docs/Accreditation_Certificate_Junior.pdf" target="_blank">Good Sports Accreditation Certificate - Junior</a><BR>
        </p>
        <p>
          <a href="https://www.community.cricket.com.au/coach" target="_blank">Coaching/Training Resources</a><BR>
        </p>
        <p>
          <a href="https://www.community.cricket.com.au/clubs/covid-19" target="_blank">Covid Resources</a><BR>
        </p>
        </div>
    </div>
  </div>

</div>
<div class="row">
  
  <div class="col-sm-6">
    <div class="feature feature-icon-large">
      <div class="pull-left">
        <i class="fa fa-file-text-o"></i>
      </div>
      <div class="pull-right">
        <h5>Constitution</h5>
        <p>
          We are governed by our <a href="assets/docs/jljcc-constitution.docx" target="_blank">Club Constitution</a>, last updated in February 2017.
        </p>
      </div>
    </div>
  </div>
  
  <div class="col-sm-6">
    <div class="feature feature-icon-large">
      <div class="pull-left">
          <i class="fa fa-legal"></i>
      </div>
      <div class="pull-right">
        <h5>Disputes and Protests</h5>
        <p>
          Had an issue, on or off the field?  Our <a href="assets/docs/jljcc-complaints.docx" target="_blank">Disputes and Protests</a> policy will help you.
        </p>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
    <div class="feature feature-icon-large">
        <div class="pull-left">
          <i class="fa fa-warning"></i>
        </div>
        <div class="pull-right">
          <h5>Code of Conduct</h5>
          <p>
            Your behaviour as a representative of Jankakot Lakes Junior Cricket Club is very important, please read the <a href="http://cjcc.wa.cricket.com.au/files/16158/files/Codes%20of%20Behaviour/CJCC%20Codes%20of%20Behaviour.pdf" target="_blank">Code of Conduct</a>
          </p>
        </div>
      </div>
  </div>
